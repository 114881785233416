﻿/// <reference path="../_variables.scss" />

@font-face {
    font-family: 'font';
    font-style: normal;
    font-weight: 700;
    src: local('Segoe UI Bold'), local('SegoeUI-bold'), local('segoeuib');
}

@font-face {
    font-family: 'font';
    font-style: normal;
    font-weight: 600;
    src: local('Segoe UI Semibold'), local('SegoeUI-Semibold');
}

html, body {
    height: 100%;
    color: #343434;
    font-family: "Segoe UI", sans-serif;
    font-size: 18pt;
}

body p {
    font-size: 18pt;
}

a {
    color: #fff;
}

h2 {
    font-size: 30px;
}

.wrap {
    min-height: 100%;
    height: auto;
    padding-top: 45px;
}

.wrap > .container {
    padding: 70px 15px 20px;
}

.footer {
    height: 60px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    padding-top: 20px;
}

.jumbotron {
    text-align: center;
    background-color: transparent;
}

.jumbotron .btn {
    font-size: 21px;
    padding: 14px 24px;
}

.not-set {
    color: #c55;
    font-style: italic;
}

header {
    background: white;
    padding-left: 1px;
    height: 50px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    border-bottom: 2px $yellow solid;
    padding-left: 15px;
    padding-right: 15px;

    img {
        height: 50px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    h3 {
        font-size: 20px;
        margin-bottom: 5px;
    }

    .btn {
        color: white;
        padding: 6px 10px;
        font-size: 18px;
    }
}

/* add sorting icons to gridview sort links */
a.asc:after, a.desc:after {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    padding-left: 5px;
}

a.asc:after {
    content: /*"\e113"*/ "\e151";
}

a.desc:after {
    content: /*"\e114"*/ "\e152";
}

.sort-numerical a.asc:after {
    content: "\e153";
}

.sort-numerical a.desc:after {
    content: "\e154";
}

.sort-ordinal a.asc:after {
    content: "\e155";
}

.sort-ordinal a.desc:after {
    content: "\e156";
}

.grid-view th {
    white-space: nowrap;
}

.hint-block {
    display: block;
    margin-top: 5px;
    color: #999;
}

.error-summary {
    color: #a94442;
    background: #fdf7f7;
    border-left: 3px solid #eed3d7;
    padding: 10px 20px;
    margin: 0 0 15px 0;
}

/* align the logout "link" (button in form) of the navbar */
.nav li > form > button.logout {
    padding: 15px;
    border: none;
}

@media(max-width:767px) {
    .nav li > form > button.logout {
        display: block;
        text-align: left;
        width: 100%;
        padding: 10px 15px;
    }
}


.nav > li > form > button.logout:focus,
.nav > li > form > button.logout:hover {
    text-decoration: none;
}

.nav > li > form > button.logout:focus {
    outline: none;
}

.main_cointainer {
    max-width: 1080px !important;
    margin-left: auto;
    margin-right: auto;
    margin-top: 16px;
}

.similar_section_title_div {
    border-bottom: 4px solid #fff;
    padding: 0;
    margin-bottom: 10px;
    margin-top: 30px;
}

.similar_section_title {
    font-size: 20px;
    margin-bottom: 8px;
    font-weight: 500;
}

table {
    width: 100%;
    border-radius: 4px;
    min-width: 320px !important;
}

.table_head {
    background-color: $yellow;
    color: #343434;
}

.table_body {
    background-color: #e3e3e3;
    color: #343434;
}

.table_body a {
    color: #343434 !important;
    padding: 15px 30px;
}

.table_body .empty {
    color: #343434 !important;
    padding-left: 30px;
    margin-top: 15px;
    font-size: 18pt;
}

.product_container table td {
    padding: 15px 30px;
}

.table_head th {
    border-left: 1px solid #fff !important;
    border-right: 1px solid #fff !important;
    padding: 10px 10px;
}

th:first-child {
    border-left: none !important;
}

th:last-child {
    border-right: none !important;
}

table td {
    border-bottom: 1px solid #d3d3d3 !important;
}

.list_table tr:hover {
    //text-decoration: underline;
    background-color: #C4EBE9;
}

td a {
    width: 100%;
    display: block;
}

.table_body h2 {
    color: #000 !important;
}

.button_action {
    background-color: #18afa8;
    color: #fff;
    padding: 0 10px;
    font-size: 16pt;
    font-weight: 400;
    height: 30px;
}



.button_action, a:hover {
    color: #fff;
    text-decoration: none;
}

.fix_margin_bottom {
    margin-bottom: 30px;
}

.margin_bottom_60px {
    margin-bottom: 60px !important;
}

.margin_top_60px {
    margin-top: 30px;
}


.pull-right {
    float: right !important;
    text-align: right;
}

.pull-left {
    float: left;
}

.btn {
    border-radius: 5px;
}

.btn-default {
    background-color: $yellow;
    color: #fff;
    border: none;
    padding: 10px 10px;
    font-size: 18pt;
    border-radius: 0;
}

.btn-default:hover {
    background-color: $yellow;
    color: #fff;
    border: none;
    padding: 10px 10px;
    font-size: 18pt;
}


.btn-search {
    background-color: $yellow;
    color: #fff;
    font-size: 18pt;
    width: 100%;
    text-align: center;
    border-radius: 0;
    padding: 6px 12px;
}

.btn-search:hover {
    background-color: $yellow;
    color: #fff;
}

#searchmodel-item {
    height: 39px !important;
    font-size: 16px;
}

#main_text {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 40px;
}

#main_text h2 {
    font-size: 30px;
    width: 100%;
}

#welcome_text {
    text-align: center;
    margin-top: 120px;
}

#welcome_text p {
    margin-bottom: 20px;
}

#welcome_text .btn {
    display: block;
    width: 90%;
    max-width: 300px;
    margin: 0 auto 17px auto;
    font-size: 22px;
}

#welcome_text .btn-primary {
    background-color: $yellow;
    border-color: $yellow;
    margin-top: 40px;
    border-radius: 0;
}

#welcome_text input[type="checkbox"] {
    top: 8px;
    height: 16px;
    width: 16px;
}

.own_col {
    padding: 0;
    margin: 0;
}

.search_top p {
    font-size: 18pt;
    text-align: center;
}

table th {
    border: none !important;
}

.product_container {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
}

.index_img_cont img {
    margin-top: 50px;
    margin-bottom: 20px;
}

.index_img_cont {
    text-align: center;
    align: center;
    width: 100%;
}

.index_img_cont .btn-default {
    width: 90%;
    margin-top: 45px;
}

.pho_footer {
    font-size: 14px;
    font-style: italic;
    text-align: right;
    color: #fff !important;
    padding: 5px 30px !important;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 2;
    margin: auto;
}

.exp p {
    font-size: 14px;
    font-style: italic;
    text-align: right;
    margin-bottom: 0;
}

#searchmodel-item {
    height: 48px !important;
    width: 100%;
    font-size: 18pt;
    border-radius: 0;
}

.no_padding_right {
    padding-right: 0;
}

.fix_position_bottom {
    position: fixed !important;
}

.choosen_product td {
    font-style: italic;
}

.min_width_210px {
    min-width: 210px;
}

.user-index {
    color: black;
}

.table-striped > tbody > tr:nth-of-type(odd) {
    color: black;
}

.user-index h1, .user-index .summary, .table-striped > tbody > tr {
    color: white;
}

.user-index tbody tr td {
    line-height: 1em;
    padding: 8px !important;
}

.user-index tbody tr td a {
    display: inline;
    color: black;
}

.pagination a {
    color: #343434;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
}

.pagination a.active {
    background-color: $yellow;
    color: white;
}

.pagination a:hover:not(.active) {
    background-color: #fff;
    color: #343434;
}

#login-form .form-control {
    padding: 6px 12px;
    height: auto;
}

#login-form .error-summary {
    background: transparent;
    border: none;
}

.exp {
    padding-bottom: 30px;
}
