﻿/// <reference path="../_variables.scss" />

body.daxa {
    color: white;

    #top_nav {
        background: #343434;
        border-bottom: 2px black solid;

        img {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .wrap {
        background: #343434;
    }

    .btn-search {
        background: $daxa;
    }

    .btn-default {
        background: $daxa;
    }

    .table_head {
        background-color: $daxa-secondary;
    }

    #welcome_text .btn-primary {
        background-color: $daxa;
        border-color: $daxa;
        margin-top: 40px;
        border-radius: 0;
    }

    .pagination {
        a {
            color: white;

            &.active {
                background-color: $daxa;
            }
        }
    }
}
